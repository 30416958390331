@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap');

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Typography
$nft-typo-level: mat-typography-level(
  $font-family: Montserrat,
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

/* Definition
mat.define-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: normal
)*/
$nft-typography: mat-typography-config(
  $font-family: 'Montserrat, Helvetica Neue, sans-serif',
  $headline: mat-typography-level(64px, 65px, 900, $letter-spacing: -0.5px),
  $title: mat-typography-level(24px, 36px, 500, $letter-spacing: -1px),
  $subheading-1: mat-typography-level(18px, 20.7px, 700, $letter-spacing: 0),
  $body-1: mat-typography-level(16px, 18.4px, 400, $letter-spacing: 0.5px),
  $body-2: mat-typography-level(14px, 16.1px, 400, $letter-spacing: 0.5px),
);

@include mat-core($nft-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$marketplace-primary: mat-palette($mat-pink);
$marketplace-accent: mat-palette($mat-blue-grey, A200, A100, A400);
$marketplace-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$marketplace-theme: mat-light-theme(
  (
    color: (
      primary: $marketplace-primary,
      accent: $marketplace-accent,
      warn: $marketplace-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($marketplace-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

a:not(p > a) {
  text-decoration: none;
  color: white;
}

.mat-tooltip {
  font-family: 'Montserrat';
  font-size: 16px;
  background: #0C031C;
  border: 1px solid #A156DB;
}

.courrier-font {
  font-family: 'Courier Prime';
}

p {
  font-size: 16px;
}

h4.courrier-font {
  font-size: 18px;
  font-family: 'Courier Prime';
}

.custom-button {
  background-color: #A156DB;
  color: #FFF;
  &.mat-flat-button {
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 50px;
  }
}

.custom-button:hover {
  transition: 0.3s;
  background-color: rgba(#A156DB, 0.9);
}
